import React from 'react';
import { useRef } from 'react';
import { Button, Typography } from '@mui/material';
import { detailsStyles } from './DetailsStyles';
import pptxgen from 'pptxgenjs';

export default function DetailsPage(props) {
    const historyPoints = props.history ? props.history.split('\n') : null;
    const findingPoints = props.findings ? props.findings.split('\n') : null;

    const imageRef = useRef(null);
    const imageRef2 = useRef(null);
    const imageRef3 = useRef(null);
    const imageRef4 = useRef(null);
    const imageRef5 = useRef(null);


    const handleDownloadPPT = () => {
        const pptx = new pptxgen();
        const slide = pptx.addSlide();

        let yPos = 0.2;
        const maxWidth = 11; // Maximum x-coordinate before wrapping
        const lineHeight = 0.2; // Height between lines


        slide.addText('Patient', { x: 0.3, y: yPos, fontSize: 18, bold: true, color: '#7a0019' });
        yPos += 0.2;
        slide.addText(`${props.age}-year-old ${props.sex === 'F' ? 'Female' : 'Male'}`, { x: 0.3, y: yPos, fontSize: 12, color: '333333' });
        yPos += 0.4;
        slide.addText('Chief Complaint', { x: 0.3, y: yPos, fontSize: 18, bold: true, color: '7a0019' });
        yPos += 0.3;
        const complaintLines = [];
        let line = '';
        for (let i = 0; i < props.complaint.length; i++) {
            const char = props.complaint[i];
            const estimatedWidth = (line.length + 1) * 0.12; // Estimate the width based on the number of characters
            if (estimatedWidth < maxWidth) {
                line += char;
            } else {
                complaintLines.push(line);
                line = char;
            }
        }
        if (line !== '') {
            complaintLines.push(line);
        }

        // Add each line of the complaint text separately
        complaintLines.forEach((line, index) => {
            slide.addText(line, {
                x: 0.3, // Start from x = 0.3, then move to x = 5 for subsequent lines
                y: yPos + index * lineHeight, // Adjust y position based on the line index
                fontSize: 12,
                color: '333333'
            });
        });

        // Update yPos based on the number of lines added
        yPos += complaintLines.length * lineHeight * 0.5;
        // Add patient history points
        if (historyPoints) {
            yPos += 0.5;
            slide.addText('Background and/or Patient history', { x: 0.3, y: yPos, fontSize: 18, bold: true, color: '7a0019' });
            yPos += 0.3;
            historyPoints.forEach(point => {
                slide.addText(point.trim(), { x: 0.3, y: yPos, fontSize: 12, color: '333333', bullet: true });
                yPos += 0.25;
            });
        }

        // Add findings points
        if (findingPoints) {
            yPos += 0.1;
            slide.addText('Findings', { x: 0.3, y: yPos, fontSize: 18, bold: true, color: '7a0019' });
            yPos += 0.3;
            findingPoints.forEach(point => {
                slide.addText(point.trim(), { x: 0.3, y: yPos, fontSize: 12, color: '333333', bullet: true });
                yPos += 0.25;
            });
        }

        if (props.image) {
            const imagePath = imageRef.current.currentSrc;
            slide.addImage({ path: imagePath, x: 7, y: 0.2, w: 2.5, h: 2.5 })
        }
        if (props.image2) {
            const imagePath2 = imageRef2.current.currentSrc;
            slide.addImage({ path: imagePath2, x: 7, y: 3 })
        }
        if (props.image3) {
            const imagePath3 = imageRef3.current.currentSrc;
            slide.addImage({ path: imagePath3, x: 7, y: 4 })
        }
        if (props.image4) {
            const imagePath4 = imageRef4.current.currentSrc;
            slide.addImage({ path: imagePath4, x: 7, y: 5 })
        }
        if (props.image5) {
            const imagePath5 = imageRef5.current.currentSrc;
            slide.addImage({ path: imagePath5, x: 7, y: 6 })
        }
        pptx.writeFile({ fileName: 'PatientDetails.pptx' })
    };

    return (
        <div style={detailsStyles.maindiv}>
            <div style={detailsStyles.contentDiv}>
                <div style={detailsStyles.textDiv}>
                    <div style={{ height: "97%" }}>
                        <div style={detailsStyles.sectionDiv}>
                            <div style={detailsStyles.headingDiv}>
                                <Typography variant="h6" style={detailsStyles.typography}>Patient</Typography>
                            </div>
                            <Typography style={detailsStyles.contentTypography}>{props.age} year old {props.sex === 'F' ? 'Female' : props.sex === 'M' ? 'Male' : 'Non-binary'}</Typography>
                        </div>
                        <div style={detailsStyles.sectionDiv}>
                            <div style={detailsStyles.headingDiv}>
                                <Typography variant="h6" style={detailsStyles.typography}>Chief Complaint</Typography>
                            </div>
                            <Typography style={detailsStyles.contentTypography}>{props.complaint}</Typography>
                        </div>
                        {historyPoints &&
                            <div style={detailsStyles.sectionDiv}>
                                <div style={detailsStyles.headingDiv}>
                                    <Typography variant="h6" style={detailsStyles.typography}>Background and/or Patient history</Typography>
                                </div>
                                <Typography component="ul" style={detailsStyles.contentTypography}>
                                    {historyPoints.map((point, index) => (
                                        <li key={index}>{point.trim()}</li>
                                    ))}
                                </Typography>
                            </div>
                        }
                        {findingPoints &&
                            <div style={detailsStyles.sectionDiv}>
                                <div style={detailsStyles.headingDiv}>
                                    <Typography variant="h6" style={detailsStyles.typography}>Findings</Typography>
                                </div>
                                <Typography component="ul" style={detailsStyles.contentTypography}>
                                    {findingPoints.map((point, index) => (
                                        <li key={index}>{point.trim()}</li>
                                    ))}
                                </Typography>
                            </div>
                        }
                    </div>

                    <div style={{ height: "3%", textAlign: 'right' }}>
                        <Button style={{ backgroundColor: "#FFCC33", color: "black" }} onClick={handleDownloadPPT}>Download</Button>
                    </div>
                </div>
                <div style={detailsStyles.imageDiv}>
                    {props.image && (
                        <img ref={imageRef} style={detailsStyles.image} src={require(`${props.image}`)} alt="Patient" />
                    )}
                    {props.image2 && (
                        <img ref={imageRef2} style={detailsStyles.image} src={require(`${props.image2}`)} alt="Patient" />
                    )}
                    {props.image3 && (
                        <img ref={imageRef3} style={detailsStyles.image} src={require(`${props.image3}`)} alt="Patient" />
                    )}
                    {props.image4 && (
                        <img ref={imageRef4} style={detailsStyles.image} src={require(`${props.image4}`)} alt="Patient" />
                    )}
                    {props.image5 && (
                        <img ref={imageRef5} style={detailsStyles.image} src={require(`${props.image5}`)} alt="Patient" />
                    )}
                </div>

            </div>
            <div style={detailsStyles.referenceDiv}>
                <Typography style={{ fontSize: "11px" }} >{props.Reference}</Typography>
            </div>
        </div>
    );
}
